<template>
  <div class="app-container">
    <nav>
      <div class="tips">
          <!-- <div class="text">开票终端</div> -->
          <el-input v-model="queryValue" placeholder="请输入开票终端"></el-input>
          <el-button @click="initTable" type="primary" class="btn" v-auth='"invoicingTerminal:lookup"'>查询</el-button>
      </div>
      <div class="operation">
        <el-button @click="add" type="primary" class="btn" v-auth='"invoicingTerminal:add"'>+ 新增</el-button>
        <el-button @click="selectDel" type="primary" class="btn"><i class="iconfont iconicon-05" v-auth='"invoicingTerminal:delete"'></i> 批量删除</el-button>
      </div>
    </nav>

    <div class="insurance_table" v-loading='isLoading'>
       <!-- :hideSinglePage='false' :selectionType='true'-->
      <comps-table :tableData="tableData" :tableHeader="tableHeader" @multipleSelection="handleSelectionChange" :selectionType='true'  :total='total' @current-events="initTable" @size-events='savePage' class="comp_table" v-if="tableData.length">
      </comps-table>
      <nodata v-else message="暂无数据" class="no_data" />
    </div>

    <del-dialog :visible="delDialog" :cancelDialog="cancelDialog" :confirmDialog="confirmDialog" :isdeling="isdeling" :config="config">
      <div class="answer">亲，确认要删除吗？</div>
    </del-dialog>

   


  </div>
</template>

<script>
  import delDialog from '@/components/Dialog'
  import compsTable from "@/components/table/table";
  import { insurancesList, deleteInsurances } from '@/api/insurances'
  import { findTerminal, findTerminalList, delTerminal } from '@/api/setInvoice'
  import tipsDialog from './dialog/showTips'
  export default {
    components: { delDialog ,compsTable ,  tipsDialog},
    data() {
      return {
        isLoading: false,
        hasData: false,
        // 开票终端代码 纳税人识别号 纳税人名称 主管税务机关名称
        // billingTerminalCode  taxId taxName swjgmc
        tableHeader: [
          { prop: "kpddm", label: "开票终端代码", center: 'left' ,
          render: (h, data) => {
              return (
                <span>
                  {data.row.kpddm}
                </span>
              );
            },
          },
          {prop:'taxId',label:'纳税人识别号',center: 'left',},
          {prop:'taxName',label:'纳税人名称',center: 'left',},
          {prop:'swjgmc',label:'主管税务机关名称',center: 'left',},
          {
            label: "操作",
            center: 'center',
            width:'180',
            render: (h, data) => {
              return (
                  <span>
                    <el-button class='btn' type="text" v-auth="invoicingTerminal:check" onClick={() => { this.detection(data.row);}}>
                      检测
                    </el-button>
                    <el-button class='btn' type="text" v-auth="invoicingTerminal:edit" onClick={() => { this.edit(data.row);}}>
                      编辑
                    </el-button>
                    <el-button class='btn' type="text" v-auth="invoicingTerminal:delete" onClick={() => { this.delete(data.row);}}>
                      删除
                    </el-button>
                  </span>
                );
            },
          },
        ],
        queryValue:'',
        page: 1,
        tableData: [],
        total: 0,
        pageSize: 10,
        isdeling: false,
        multipleSelection:[],

        config: {
          top: '20vh',
          width: '16%',
          title: '提示',
          btnTxt: ["确定", "取消"]
        },
        // 保险id
        id: '',

        delDialog: false,
        importVis: false,
        exportVis:false,
        

      }
    },
    methods: {
      add() {
        // einvAlert.warning('提示','当前公司尚未通过开票接入申请,无法使用该模块','确定','我知道了')
        this.$router.push('invoicingTerminal/add')
      },
      edit(row) {
        this.$router.push({
          path: 'invoicingTerminal/edit',
          query: {
            id: row.id
          }
        })
      },
      //检测
      detection(row) {
        console.log(row,'row11111')
        this.$store.dispatch("invoiceServer", {
        companyId : row.companyId,
        kpzdbs: row.kpddm,
        skUrl:row.skUrl,
        fplxdm:'',
        }).then(res => {
              if (res.success) {
               console.log('123',res)
               einvAlert.success("提示","税控服务器连接成功")
              } else {
                this.$message.error(res.msg)
              }
            }).catch(err => {
              this.isLoading = false
            })
      },

      handleSelectionChange(val) {
        this.multipleSelection = val;
      },

      //批量删除
      selectDel(){
        if (this.multipleSelection.length == 0) {
          einvAlert.error("提示",'请选中需要删除的数据！')
        } else{
          this.delDialog = true
          this.id = this.multipleSelection.map(item => {
                  return item.id
                }).join(',')
        }
      },
      //单选删除
      delete(row) {
        this.delDialog = true
        this.id = row.id
      },
      cancelDialog() { this.delDialog = false },

      confirmDialog() {
        this.isdeling = true
        delTerminal({
          companyId : this.getToken('companyId'),
          ids: this.id
        }).then(res => {
          if (res.success) {
            einvAlert.success("提示",'删除成功')
            this.delDialog = false
            this.initTable()
          } else {
            einvAlert.error("提示",res.msg)
          }
          this.isdeling = false
        }).catch(err => {
          this.isdeling = false
        //  einvAlert.error("提示",err)
        })
      },
      savePage(val) {
        this.pageSize = val
        this.initTable()
      },
      // 开票终端列表
      initTable() {
        this.isLoading = true;
        this.$store.dispatch("findTerminal", {
          kpddm: this.queryValue,
          page: this.page,
          rows: this.pageSize,
          companyId : this.getToken('companyId'),
        }).then(res => {
          if (res.success) {
            this.isLoading = false;
            this.tableData = res.data;
            this.total = res.data.length;
          } else {
            this.isLoading = false;
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
          einvAlert.error("提示",err)
          this.isLoading = false;
        })
      },

      imports(){
        this.importVis = true;
      },
      importCancel(){
        this.importVis = false;
      },
     handleExport(){
        this.exportVis = true;
     },
      exportCancel(){
        this.exportVis = false;
      },
      showTips(){
        this.promptVisible = true;
      },
      tipsCancel(){
        this.promptVisible = false;
      }
    },
    mounted() {
      this.initTable()
    }
  }
</script>

<style lang="less">
  nav{
    .text{
      font-size: 14px;
      width: 110px;
    }
    .tips{
      display: flex;
      align-items: center;
    }
    .btn{
      margin-left: 10px;
    }
  }
  .btn{
    margin-right: 10px;
  }
  .insurance_table {
    height: calc(100% - 35px);
    padding: 24px 20px;
    background-color: #fff;
    .comp_table {
      // border: 1px solid #ebeef5;
    }
  }
  // .answer {
  //   text-align: center;
  //   padding: 24px 0 14px;
  //   font-size: 16px;
  // }
</style>
